import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { mapStateToProps, RESPONSE_ERROR_FORMATTER } from 'utilities';
import { FormInput, withFormGroup, Modal } from 'components';
import { ClipLoader } from 'react-spinners';
import { Layout } from 'containers';
import { PAGES } from 'config';
import {
  UPDATE_USER,
  UPDATE_PASSWORD,
  DELETE_ACCOUNT,
} from 'actions/user';

const Input = withFormGroup(FormInput);
const isBrowser = () => typeof window !== 'undefined';

class EditUserPage extends PureComponent {
  constructor(props) {
    super(props);

    const { user } = this.props;

    this.state = {
      accountErrors: [],
      passwordErrors: [],
      open: false,
      isActive: false,
      firstName: user.details.firstName,
      password: '',
      newPassword: '',
      confirmPassword: '',
    };

    this.onFirstNameChanged = this.onFirstNameChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onNewPasswordChanged = this.onNewPasswordChanged.bind(this);
    this.onConfirmPasswordChanged = this.onConfirmPasswordChanged.bind(this);
    this.handleEditAccountFormSubmit = this.handleEditAccountFormSubmit.bind(this);
    this.handleEditPasswordFormSubmit = this.handleEditPasswordFormSubmit.bind(this);
    this.handleDeleteAcccount = this.handleDeleteAcccount.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    const { user } = this.props;

    if (user.session.token === null && isBrowser()) {
      navigate(PAGES.HOME.PATH);
    }
  }

  onFirstNameChanged(e) {
    this.setState({ firstName: e.currentTarget.value });
  }

  onPasswordChanged(e) {
    this.setState({ password: e.currentTarget.value });
  }

  onNewPasswordChanged(e) {
    this.setState({ newPassword: e.currentTarget.value });
  }

  onConfirmPasswordChanged(e) {
    this.setState({ confirmPassword: e.currentTarget.value });
  }

  showSpinner() {
    this.setState({ isActive: true });
  }

  hideSpinner() {
    this.setState({ isActive: false });
  }

  handleEditAccountFormSubmit(e) {
    e.preventDefault();
    const { user, dispatch } = this.props;
    const FORM = e.currentTarget;
    const { firstName } = this.state;

    if (!FORM.checkValidity()) {
      this.setState({
        accountErrors: ['Invalid form data'],
      });
      return;
    }

    (async () => {
      this.showSpinner();

      const UPDATE = await UPDATE_USER(
        dispatch,
        user.session.token,
        user.session.email,
        firstName,
      );

      this.setState({ accountErrors: [] });

      if (UPDATE.data.status === 401) {
        this.hideSpinner();
        this.setState({
          accountErrors: RESPONSE_ERROR_FORMATTER(UPDATE.data.errors),
        });
      } else {
        this.hideSpinner();

        dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: 'update-user',
            type: 'success',
            content: 'Your information has been updated successfully',
          },
        });

        setTimeout(() => {
          dispatch({
            type: 'MESSAGES_REMOVE',
            payload: 'update-user',
          });
        }, 5000);
      }
    })();
  }

  passwordMatch() {
    const { newPassword, confirmPassword } = this.state;

    return newPassword === confirmPassword;
  }

  handleEditPasswordFormSubmit(e) {
    e.preventDefault();

    if (!this.passwordMatch()) {
      this.setState({
        passwordErrors: ['Confirmation password does not match'],
      });
      return;
    }

    const { user, dispatch } = this.props;
    const FORM = e.currentTarget;
    const {
      password,
      newPassword,
      confirmPassword,
    } = this.state;

    if (!FORM.checkValidity()) {
      this.setState({
        passwordErrors: ['Invalid form data'],
      });
      return;
    }

    (async () => {
      this.showSpinner();

      const UPDATE = await UPDATE_PASSWORD(
        user.session.token,
        user.session.email,
        password,
        newPassword,
        confirmPassword,
      );

      this.setState({ passwordErrors: [] });

      if (UPDATE.data.status === 401) {
        this.hideSpinner();
        this.setState({
          passwordErrors: RESPONSE_ERROR_FORMATTER(UPDATE.data.errors),
        });
      } else {
        this.hideSpinner();
        this.setState({
          password: '',
          newPassword: '',
          confirmPassword: '',
        });

        dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: 'update-user',
            type: 'success',
            content: 'Your information has been updated successfully',
          },
        });

        document.getElementById('InputPassword').value = '';
        document.getElementById('InputNewPassword').value = '';
        document.getElementById('InputConfirmPassword').value = '';

        setTimeout(() => {
          dispatch({
            type: 'MESSAGES_REMOVE',
            payload: 'update-user',
          });
        }, 5000);
      }
    })();
  }

  handleDeleteAcccount(e) {
    e.preventDefault();
    const { user, dispatch } = this.props;

    (async () => {
      await DELETE_ACCOUNT(
        dispatch,
        user.session.token,
        user.session.email,
      );

      isBrowser() && navigate(PAGES.HOME.PATH); // eslint-disable-line
    })();
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  render() {
    const {
      accountErrors,
      passwordErrors,
      firstName,
      password,
      newPassword,
      confirmPassword,
      open,
      isActive,
    } = this.state;

    return (
      <Layout page="user-edit">
        <div className={`backdrop ${isActive ? 'active' : ''}`}>
          <ClipLoader
            className="pt-3"
            sizeUnit="px"
            size={50}
            color="#123abc"
          />
        </div>
        <section className="l-edit-page l-section">
          <div className="l-container">
            <div className="l-grid l-grid--2">
              <div>
                <h2 className="c-heading c-heading--h2">Edit account</h2>
                <form action="" className="l-form" style={{ marginTop: '2rem' }} onSubmit={this.handleEditAccountFormSubmit}>
                  <ul className="l-form__errors">
                    {accountErrors.map(error => (
                      <li key={error}>
                        {error}
                      </li>
                    ))}
                  </ul>
                  <div className="l-form__contents">
                    <Input
                      type="text"
                      name="FirstName"
                      id="InputFirstName"
                      placeholder="Name"
                      label="Name &#42;"
                      required
                      value={firstName}
                      onChange={this.onFirstNameChanged}
                      attrs={{ autoFocus: true }}
                    />
                  </div>
                  <button
                    className="c-button c-button--border"
                    type="submit"
                  >
                    Save
                  </button>
                </form>
                <h2 className="c-heading c-heading--h2" style={{ marginTop: '5rem' }}>Edit password</h2>
                <form action="" className="l-form" style={{ marginTop: '2rem' }} onSubmit={this.handleEditPasswordFormSubmit}>
                  <ul className="l-form__errors">
                    {passwordErrors.map(error => (
                      <li key={error}>
                        {error}
                      </li>
                    ))}
                  </ul>
                  <div className="l-form__contents">
                    <Input
                      type="password"
                      name="Password"
                      id="InputPassword"
                      placeholder="Password"
                      label="Password"
                      minLength={8}
                      autoComplete={false}
                      value={password}
                      onChange={this.onPasswordChanged}
                      attrs={{ required: true }}
                    />
                    <Input
                      type="password"
                      name="NewPassword"
                      id="InputNewPassword"
                      placeholder="New Password"
                      label="New Password"
                      minLength={8}
                      autoComplete={false}
                      value={newPassword}
                      onChange={this.onNewPasswordChanged}
                      attrs={{ required: true }}
                    />
                    <Input
                      type="password"
                      name="ConfirmPassword"
                      id="InputConfirmPassword"
                      placeholder="Confirm Password"
                      label="Confirm Password"
                      minLength={8}
                      autoComplete={false}
                      value={confirmPassword}
                      onChange={this.onConfirmPasswordChanged}
                      attrs={{ required: true }}
                    />
                  </div>
                  <button
                    className="c-button c-button--border"
                    type="submit"
                  >
                    Save
                  </button>
                </form>
                <h2 className="c-heading c-heading--h2" style={{ marginTop: '5rem' }}>Delete account</h2>
                <p>Deleting your account will delete your access and all your information on this site.</p>
                <br />
                <button
                  className="c-button c-button--border"
                  type="button"
                  onClick={() => {
                    this.handleOpen();
                  }}
                >
                  Delete account
                </button>
                <Modal
                  open={open}
                  closeHandler={() => {
                    this.handleClose();
                  }}
                >
                  <div className="c-confirmation-modal">
                    <div className="c-confirmation-modal__main">
                      <h2 className="c-heading c-heading--h3">Delete account</h2>
                      <p>Are you sure you want to continue?</p>
                      <div className="c-modal__footer">
                        <button
                          className="c-button c-button--border-alt c-button--small"
                          type="button"
                          onClick={this.handleClose}
                        >
                          No, cancel
                        </button>
                        <button
                          className="c-button c-button--primary c-button--small"
                          type="button"
                          onClick={this.handleDeleteAcccount}
                        >
                          Yes, delete
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

EditUserPage.propTypes = {
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(EditUserPage);
